module.exports = {
	'seo': {
		title: "Search engine optimization (SEO)",
		description: "50% of almost every consumer journey starts with online research and brand success means turning up as the right answer every single time.",
		sections: [
			{
				icon: 'telescope',
				title: 'Our POV',
				content: `<p>In today’s digital landscape, it is important for brands to seize micro-moments with the right attitude in terms of both structure and content in order to make sure the right information ends up meeting with the right user.</p>
					<p>Achieving a perfect state of organic growth in your business, means continuously optimizing consumer’s journey around your brand as well as investing in technology around the clock.</p>`
			},
			{
				icon: 'expertise',
				title: 'Expertise',
				content: `<ul>
					<li>SEO Strategy</li>
					<li>Technical Analysis</li>
					<li>Content Strategy & Analysis</li>
					<li>Keyword Research</li>
					<li>Off-Site Development</li>
				</ul>`,
			},
			{
				icon: 'approach',
				title: 'Our Approach',
				content: '<strong>A. Tools, tools, tools</strong><p>We invest in the technology before defining the strategy.</p><strong>B. Brand teams</strong><p>We work as brand teams to make sure brand know-how is available at all times and growth is consistent.</p>'
			}
		]
	},
	'digital-media-buying': {
		title: "Digital media buying",
		description: "Creating an effective brand ecosystem for the best possible marketing process, end-to-end consumer journey on online platforms is essential.",
		sections: [
			{
				icon: 'telescope',
				title: 'Our POV',
				content: '<p>Our media planning team works closely with top publishers of Turkey to create the best experience for your customers while making sure brand safety and transparency is sustained.</p>'
			},
			{
				icon: 'expertise',
				title: 'Expertise',
				content: `<ul>
					<li>Media Planning</li>
					<li>Media Buying</li>
					<li>Traditional Media</li>
					<li>Paid Influencer Activation</li>
				</ul>`,
			},
			{
				icon: 'approach',
				title: 'Our Approach',
				content: `<p>We work in brand teams to make sure brand know-how is available at all times and growth is consistent.</p>
				<ul>
					<li>Full Funnel Approach</li>
					<li>Brand Teams</li>
				</ul>`
			}
		]
	},
	'data-and-analytics': {
		title: "Data & Analytics",
		description: "Discovering the reasons behind consumer behavior while making sure they are led to right conversion funnels is one of the most important.",
		sections: [
			{
				icon: 'telescope',
				title: 'Our POV',
				content: '<p>In the century of data, marketers and professionals are forced to dig up any kind of information to be able to keep improving ROI every single day. With our team of experts, we help you do exactly that.</p>'
			},
			{
				icon: 'expertise',
				title: 'Expertise',
				content: `<ul>
					<li>Performance Analytics</li>
					<li>Advanced Marketing Sciences</li>
					<li>Business Intelligence</li>
					<li>A/B Testing</li>
					<li>Report Development</li>
					<li>Behavioral Modeling</li>
				</ul>`,
			},
			{
				icon: 'approach',
				title: 'Our Approach',
				content: `<p>We work in brand teams to make sure brand know-how is available at all times and growth is consistent.</p>
				<ul>
					<li>From Descriptive to Prescriptive</li>
					<li>Brand Teams</li>
				</ul>`
			}
		]
	},
	'performance-marketing': {
		title: "Performance marketing",
		description: "Reaching the right audience at the right time in order to grow your business with the right KPIs.",
		sections: [
			{
				icon: 'telescope',
				title: 'Our POV',
				content: '<p>Combining your brand’s legacy with today’s latest consumer insights, our teams help you make the most of your paid investments while making sure brand data is flowing through the right channels. </p>'
			},
			{
				icon: 'expertise',
				title: 'Expertise',
				content: `<ul>
					<li>Google Ads Advertising</li>
					<li>Facebook Business Manager</li>
					<li>Paid Social Platform Investments</li>
					<li>Organic Post Boost</li>
					<li>Marketing Funnel Implementation</li>
				</ul>`,
			},
			{
				icon: 'approach',
				title: 'Our Approach',
				content: `<p>We work as brand teams to make sure brand know-how is available at all times and growth is consistent.</p>
				<ul>
					<li>From Descriptive to Prescriptive</li>
					<li>Data & Audience & Goal</li>
					<li>Brand Teams</li>
				</ul>`
			}
		]
	},
	'programmatic-media-buying': {
		title: "Programmatic media buying",
		description: "Growing your business through data activation without any human error and targeting through the latest technologies is essential for the future of your marketing.",
		sections: [
			{
				icon: 'telescope',
				title: 'Our POV',
				content: '<p>With a deep analyze of your target audience through web & business analytics, it is possible for our brand teams to reach a perfection in your programmatic media investments.</p>'
			},
			{
				icon: 'expertise',
				title: 'Expertise',
				content: `<ul>
					<li>Programmatic Buying & Trading</li>
					<li>Dynamic Re-Targeting</li>
					<li>Location & Route Based Advertising</li>
					<li>Data Management</li>
					<li>Audience Development</li>
				</ul>`,
			},
			{
				icon: 'approach',
				title: 'Our Approach',
				content: `<p>We work in brand teams to make sure brand know-how is available at all times and growth is consistent.</p>
				<ul>
					<li>Full Funnel Approach</li>
					<li>Brand Teams</li>
				</ul>`
			}
		]
	},
	'strategy': {
		title: "Strategy",
		description: "How do we grow your business? Which tools should we use? …and why?",
		sections: [
			{
				icon: 'telescope',
				title: 'Our POV',
				content: '<p>Optdcom strategy team works restlessly to answer these questions for you using the latest data, consumer insights and variety of researches while integrating full-funnel into digital landscape and making sure it is efficient.</p>'
			},
			{
				icon: 'expertise',
				title: 'Expertise',
				content: `<ul>
					<li>Consumer Segmentation</li>
					<li>Consumer Journey</li>
					<li>Digital Personas</li>
					<li>Brand Strategy</li>
					<li>Communication Planning</li>
				</ul>`,
			},
			{
				icon: 'approach',
				title: 'Our Approach',
				content: `<p>We work in brand teams to make sure brand know-how is available at all times and growth is consistent.</p>
				<ul>
					<li>From Descriptive to Prescriptive</li>
					<li>Data & Audience & Goal</li>
					<li>Brand Teams</li>
				</ul>`
			}
		]
	}
}