import React from 'react'

// Deps
import ViewportWatcher from 'controllers/viewport-watcher'

// Sections
//import Clients from 'components/sections/clients'

// Partials
import Image from 'components/partials/image'
import Link from 'components/partials/link'

// Static Assets
import image_performance_1 from 'assets/images/sections/services/performance-1.jpg'
import image_performance_2 from 'assets/images/sections/services/performance-2.jpg'

import image_seo_1 from 'assets/images/sections/services/seo-1.jpg'

import image_strategy_1 from 'assets/images/sections/services/strategy-1.jpg'

import image_data_1 from 'assets/images/sections/services/data-1.jpg'

import image_dmb_1 from 'assets/images/sections/services/dmb-1.jpg'
import image_dmb_2 from 'assets/images/sections/services/dmb-2.jpg'

export default class ServicesPage extends React.Component {
	render() {
		return (
			<React.Fragment>

				<ViewportWatcher  className="section services-highlight services-highlight-performance">
					<div className="highlight-wrap wrapper">
						<div className="highlight-text vw-item from-top delay-1">
							<h2 className="text-title">Performance marketing</h2>
							<div className="text-subtitle vw-item from-top delay-3">
								Reaching the right audience at the right time while making sure <br className="only-web" />we help you grow your business with the right KPIs. That’s what <br className="only-web" />we do here and our brand teams are looking forward to meet you.
							</div>
							<div className="vw-item from-top delay-5">
								<Link className="text-cta" href="servicedetail" params={{service: 'performance-marketing'}}><i className="icon-arrow-right"></i></Link>
							</div>
						</div>

						<div className="performance-images">
							<Image className="images-image vw-item from-bottom delay-5" src={image_performance_1} /><Image className="images-image vw-item from-bottom delay-6" src={image_performance_2} />
						</div>
					</div>
				</ViewportWatcher>

				<ViewportWatcher  className="section services-highlight services-highlight-seo">
					<div className="highlight-wrap wrapper">
						<div className="highlight-text vw-item from-top delay-1">
							<h2 className="text-title">Search engine optimization <br />(SEO)</h2>
							<div className="text-subtitle vw-item from-top delay-3">
								50% of almost every consumer journey starts with online research <br className="only-web" />and brand success means turning up as the right answer every <br className="only-web" />single time. Take a look around to see how we get the best results.
							</div>
							<div className="vw-item from-top delay-5">
								<Link className="text-cta" href="servicedetail" params={{service: 'seo'}}><i className="icon-arrow-right"></i></Link>
							</div>
						</div>

					</div>
					<Image className="seo-image vw-item from-right delay-5" src={image_seo_1} />
				</ViewportWatcher>

				<ViewportWatcher  className="section services-highlight services-highlight-strategy">
					<div className="highlight-wrap wrapper">
						<div className="highlight-text vw-item from-top delay-1">
							<h2 className="text-title">Strategy</h2>
							<div className="text-subtitle vw-item from-top delay-3">
								Our strategy team works restlessly to answer these questions for you <br className="only-web" />using the latest data, consumer insights and variety of researches <br className="only-web" />while integrating full-funnel into digital landscape and making sure <br className="only-web" />it is efficient.
							</div>
							<div className="vw-item from-top delay-5">
								<Link className="text-cta" href="servicedetail" params={{service: 'strategy'}}><i className="icon-arrow-right"></i></Link>
							</div>
						</div>

					</div>
					<Image className="strategy-image vw-item from-left delay-5" src={image_strategy_1} />
				</ViewportWatcher>

				<ViewportWatcher  className="section services-highlight services-highlight-data">
					<div className="highlight-wrap wrapper">
						<div className="highlight-text vw-item from-top delay-1">
							<h2 className="text-title">Data & analytics</h2>
							<div className="text-subtitle vw-item from-top delay-3">
								In the century of data, marketers and professionals are forced to <br className="only-web" />dig up any kind of information to be able to keep improving ROI <br className="only-web" />every single day. With our team of experts, we help you do <br className="only-web" />exactly that.
							</div>
							<div className="vw-item from-top delay-5">
								<Link className="text-cta" href="servicedetail" params={{service: 'data-and-analytics'}}><i className="icon-arrow-right"></i></Link>
							</div>
						</div>
						<Image className="data-image vw-item from-right delay-5" src={image_data_1} />
					</div>
				</ViewportWatcher>

				<ViewportWatcher  className="section services-highlight services-highlight-dmb">
					<div className="highlight-wrap wrapper">

						<div className="dmb-images">
							<Image className="dmb-image first vw-item from-bottom delay-2" src={image_dmb_1} />
							<Image className="dmb-image second vw-item from-top delay-2" src={image_dmb_2} />
						</div>
						<div className="highlight-text vw-item from-left delay-4">
							<h2 className="text-title">Digital media buying</h2>
							<div className="text-subtitle vw-item from-left delay-5">
								Our media planning team works closely with top publishers of <br className="only-web" />Turkey to create the best experience for your customers while <br className="only-web" />making sure brand safety and transparency is sustained.
							</div>
							<div className="vw-item from-top delay-5">
								<Link className="text-cta" href="servicedetail" params={{service: 'digital-media-buying'}}><i className="icon-arrow-right"></i></Link>
							</div>
						</div>
					</div>
				</ViewportWatcher>

				<ViewportWatcher  className="section services-highlight services-highlight-pmb">
					<div className="highlight-wrap wrapper">
						<div className="highlight-text vw-item from-top delay-1">
							<h2 className="text-title">Programmatic media buying</h2>
							<div className="text-subtitle vw-item from-top delay-2">
								With a deep analyze of your target audience through web & <br className="only-web" />business analytics, it is possible for our brand teams to reach a <br className="only-web" />perfection in your programmatic media investments.
							</div>
							<div className="vw-item from-top delay-5">
								<Link className="text-cta" href="servicedetail" params={{service: 'programmatic-media-buying'}}><i className="icon-arrow-right"></i></Link>
							</div>
						</div>
					</div>
				</ViewportWatcher>

			</React.Fragment>
		)
	}
}