import React, { useEffect, useState } from 'react'

// Deps
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router'
import serviceData from 'data/services'

// Partials
import Image from 'components/partials/image'
import Btn from 'components/partials/btn'
import Link from 'components/partials/link'
import clone from 'lodash/clone'

// Static Assets
import image_404 from 'assets/images/sections/notfound/404.svg'

const mapStateToProps = state => {
	return {
		mobile: state.generic.mobile,
		currentPage: state.generic.currentPage,
	};
};

const ismobile = window.innerWidth <= 768

const pageData = {
	home: {
		key: 'home',
		heading: <React.Fragment>
			<div style={{ margin: 10 }}>
				<div style={{ width: '100px', textAlign: 'center', backgroundColor: 'red', fontSize: 16, color: 'white', padding: 0 }}>Happenings</div>
				<p style={{ paddingTop: 10, fontWeight: 'normal', color: '#444' }}>Announcing our new <br /> R&D Center</p>
			</div>

			<div style={{ backgroundColor: '#221f1f', fontSize: ismobile ? '2.5rem' : '6rem', color: 'white', width: '100%', paddingLeft: 40, paddingRight: 40, paddingBottom: 20 }}>

				<p style={{ fontSize: 12, color: '#777' }}>OPT.D.COM</p>
				Bringing marketing and technology <br className="only-web" /> <strong>together</strong> like never before.
			</div>
		</React.Fragment>,
		showScroller: true,
	},
	about: {
		key: 'about',
		heading: <React.Fragment>
			We craft useful marketing <br className="only-web" />experiences that <strong>grow</strong> businesses
		</React.Fragment>,
	},
	services: {
		key: 'services',
		heading: <React.Fragment>
			We are bringing marketing, <br className="only-web" />
			technology and data <strong>together</strong> <br />
			to grow your business.
		</React.Fragment>,
	},
	servicedetail: {
		key: 'servicedetail',
		heading: false,
	},
	careers: {
		key: 'careers',
		heading: <React.Fragment>
			We’ve been waiting <br className="only-mobile" />for you. <br />Let’s work <strong>together.</strong>
		</React.Fragment>,
	},
	contact: {
		key: 'contact',
		heading: <React.Fragment>
			Drop a line <br />and we’ll <strong>be happy</strong> to <br />get back to you
		</React.Fragment>,
	},
	notfound: {
		key: 'notfound',
		heading: false,
		content: <div className="notfound-content">
			<Image src={image_404} className="notfound-image" />

			<h1 className="notfound-title">This Page Was Lost</h1>

			<div className="notfound-ctawrap">
				<Btn tag="link" href="home" className="notfound-cta" wide leftArrow>Back to Home</Btn>
			</div>
		</div>
	}
}

class Cover extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			show: false,
			pageData: false,
		}

		this.setPage = this.setPage.bind(this);
		this.timeout = null;
	}

	componentDidMount() {
		let vm = this;
		vm.setPage(vm.props.currentPage.key ? vm.props.currentPage.key : 'notfound');
		/*setTimeout(() => {
			vm.setState({ show: true })
		}, 300);*/
	}

	componentDidUpdate(prevProps) {
		if (prevProps.currentPage.key !== this.props.currentPage.key || prevProps.location.pathname !== this.props.location.pathname) {
			this.setPage(this.props.currentPage.key);
		}
	}

	setPage(key) {
		let vm = this;
		clearTimeout(vm.timeout);

		let newData = clone(pageData[key ? key : 'notfound']);

		if (key === 'servicedetail') {
			const match = matchPath(vm.props.history.location.pathname, {
				path: vm.props.currentPage.data.path,
				exact: true,
				strict: false
			});

			let service = match ? serviceData[match.params.service] : null;

			if (service) {
				newData.heading = service.title;
				newData.description = service.description;
				newData.backBtn = { href: 'services' }
			}
		}

		if (vm.state.show) {
			vm.setState({ show: false });

			vm.timeout = setTimeout(() => {
				vm.setState({ pageData: newData })
				vm.timeout = setTimeout(() => {
					vm.setState({ show: true });
				}, 30);
			}, 600);
		}
		else {
			vm.setState({ pageData: newData })
			vm.timeout = setTimeout(() => {
				vm.setState({ show: true });
			}, 300);
		}
	}

	render() {
		return (
			<div className={"section cover " + this.state.pageData.key + (this.state.show ? ' show' : '')}>
				<div className="cover-wrap wrapper">
					{(this.state.pageData && this.state.pageData.heading) &&
						<div className="cover-content">
							{this.state.pageData.backBtn &&
								<Link className="content-back" {...this.state.pageData.backBtn}>
									<i className="icon-arrow-left"></i>
								</Link>
							}
							<h1 className="content-title">
								{this.state.pageData.heading}
							</h1>
							{this.state.pageData.description &&
								<p className="content-description">
									{this.state.pageData.description}
								</p>
							}
						</div>
					}

					{(this.state.pageData && this.state.pageData.content) &&
						<React.Fragment>
							{this.state.pageData.content}
						</React.Fragment>
					}

					{(!this.props.mobile && this.state.pageData && this.state.pageData.showScroller) &&
						<div className="cover-scroller">
							<span>Scroll</span>
							<div className="scroller-indicator"></div>
							<span>Down</span>
						</div>
					}
				</div>
			</div>
		)

	}
}

export default withRouter(connect(mapStateToProps)(Cover));