import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './data/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';

// Styles
import './assets/scss/main.scss';
import ErrorBoundary from 'components/ErrorBoundary';

ReactDOM.render(
	<ErrorBoundary>
		<Provider store={store}>
			<App />
		</Provider>
	</ErrorBoundary>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
