import { combineReducers, createStore } from 'redux'
import generic from "data/store.generic";
//import checkout from "data/store.checkout";

const rootReducer = combineReducers({
	generic: generic,
})

const store = createStore(rootReducer);

export default store;