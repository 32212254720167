import React from 'react'

// Deps
import ViewportWatcher from 'controllers/viewport-watcher'
//import Responsive from 'controllers/responsive'
import { connect } from 'react-redux'

// Sections
import Clients from 'components/sections/clients'

// Partials
import Btn from 'components/partials/btn'
import Image from 'components/partials/image'

// Static Assets
import image_section_who from 'assets/images/sections/home/who.png'
import image_section_careers from 'assets/images/sections/home/careers.png'

const mapStateToProps = state => {
	return {
		mobile: state.generic.mobile,
	};
};

class HomePage extends React.Component {
	render() {
		let mobile = this.props.mobile;

		return (
			<React.Fragment>

				{/*<ViewportWatcher className="section cover">
					<div className="cover-wrap wrapper">
						<h1 className="cover-text">
							Bringing marketing and technology <br className="only-web" /><strong>together</strong> like never before.
						</h1>

						<Responsive type="only-web">
							<div className="cover-scroller">
								<span>Scroll</span>
								<div className="scroller-indicator"></div>
								<span>Down</span>
							</div>
						</Responsive>
					</div>
				</ViewportWatcher>
				*/}

				<ViewportWatcher className="section home-highlight home-highlight-who" style={{ marginTop: 20 }}> 
					<div className="highlight-wrap services-wrap wrapper" >
						<div className="who-info first" >
							<div className="info-content">
								<strong className={"info-num vw-item " + (mobile ? "from-right delay-1" : "from-left delay-5")}>50+</strong>
								<p className={"info-text vw-item " + (mobile ? "from-right delay-2" : "from-left delay-6")}>Happy people</p>
							</div>
						</div>

						<div className="who-info second">
							<div className="info-content">
								<strong className={"info-num vw-item from-left " + (mobile ? "delay-3" : "delay-7")}>40+</strong>
								<p className={"info-text vw-item from-left " + (mobile ? "delay-4" : "delay-8")}>Happy clients</p>
							</div>
						</div>

						<div className="who-info third">
							<div className="info-content">
								<strong className={"info-num vw-item " + (mobile ? "from-right delay-5" : "from-left delay-9")}>2</strong>
								<p className={"info-text vw-item " + (mobile ? "from-right delay-6" : "from-left delay-10")}>Locations</p>
							</div>
						</div>

						<div className={"who-text vw-item " + (mobile ? 'from-top delay-8' : 'from-left delay-1')}>
							<h2 className="text-title">Who we are?</h2>

							<p className={"text-content vw-item " + (mobile ? 'from-top delay-9' : 'from-left delay-2')}>We are a digital marketing agency with data and technology at its’ core; offering various services including strategy, performance and technology to make sure that our brands grow steadily.</p>

							<div className={"text-cta vw-item " + (mobile ? 'from-top delay-10' : 'from-left delay-3')}>
								<Btn tag="link" href="about" arrow white wide {...{ solid: (mobile ? true : undefined) }}>Meet Optdcom</Btn>
							</div>
						</div>

						<Image className={"who-image vw-item " + (mobile ? ' from-bottom delay-12' : 'from-bottom-delay-4')} src={image_section_who} />
					</div>
				</ViewportWatcher>

				<ViewportWatcher className="section home-highlight home-highlight-services">
					<div className="highlight-wrap services-wrap wrapper">
						<div className="services-info vw-item from-top delay-1">
							<h2 className="services-title">Our lovely <br />services</h2>
						</div>
						<div className="services-info vw-item from-top delay-3">
							<h3 className="info-title">Performance marketing</h3>
							<p className="info-text vw-item from-top delay-4">Reaching the right audience at the right time while making sure we help you grow your business with the right KPIs. That’s what we do here and our brand teams are looking forward to meet you.</p>
						</div>
						<div className="services-info vw-item from-top delay-5">
							<h3 className="info-title">SEO</h3>
							<p className="info-text vw-item from-top delay-6">50% of almost every consumer journey starts with online research and brand success means turning up as the right answer every single time. Take a look around to see how we get the best results.</p>
						</div>
						<div className="services-info vw-item from-top delay-7">
							<h3 className="info-title">Strategy</h3>
							<p className="info-text vw-item from-top delay-8">Our strategy team works restlessly to answer these questions for you using the latest data, consumer insights and variety of researches while integrating full-funnel into digital landscape and making sure it is efficient.</p>
						</div>
						<div className="services-info vw-item from-top delay-9">
							<h3 className="info-title">Data & analytics</h3>
							<p className="info-text vw-item from-top delay-10">In the century of data, marketers and professionals are forced to dig up any kind of information to be able to keep improving ROI every single day. With our team of experts, we help you do exactly that.</p>
						</div>
						<div className="services-info vw-item from-top delay-11">
							<Btn tag="link" href="services" className="text-cta" arrow wide {...{ solid: (mobile ? true : undefined) }}>All Lovely Services</Btn>
						</div>
					</div>
				</ViewportWatcher>

				<ViewportWatcher className="section home-highlight home-highlight-careers">
					<div className="highlight-wrap careers-wrap wrapper">
						<div className="careers-top">
							<h2 className="careers-title vw-item from-left delay-1">“We’ve been waiting for you. <br className="only-web" />Let’s work <strong>together.</strong>”</h2>
							<p className="careers-description  vw-item from-top delay-2">If you wish to be part of great opportunities, lead amazing brands into success and take your career into the next level check out our job listings.</p>
						</div>

						<div className="careers-icons">
							<div className="icons-item vw-item from-top delay-6">
								<i className="item-icon icon-lamp" />
								<strong className="item-text">Cosy Office</strong>
							</div>

							<div className="icons-item vw-item from-top delay-7">
								<i className="item-icon icon-computer" />
								<strong className="item-text">Pro Tools</strong>
							</div>

							<div className="icons-item vw-item from-top delay-8">
								<i className="item-icon icon-gaming" />
								<strong className="item-text">Gaming</strong>
							</div>

							<div className="icons-item vw-item from-top delay-9">
								<i className="item-icon icon-location" />
								<strong className="item-text">Great Location</strong>
							</div>

							<div className="icons-item vw-item from-top delay-10">
								<i className="item-icon icon-stopwatch" />
								<strong className="item-text">Training</strong>
							</div>

							<div className="icons-item vw-item from-top delay-11">
								<i className="item-icon icon-coffeemaker" />
								<strong className="item-text">Social Zone</strong>
							</div>
						</div>

						<Btn tag="link" href="careers" className="text-cta vw-item from-top delay-15" arrow {...{ solid: (mobile ? true : undefined) }}>Open Positions</Btn>

						<div className="careers-imagewrap">
							<Image className="careers-image vw-item from-bottom delay-3" src={image_section_careers} />
						</div>
					</div>
				</ViewportWatcher>

				<Clients home showTitle />

			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps)(HomePage);