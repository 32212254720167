import React from 'react'

// Deps
import ViewportWatcher from 'controllers/viewport-watcher'
import Papa from 'papaparse';

// Sections

// Partials
import Link from 'components/partials/link'
import Loader from 'components/partials/loader'

// Static Assets

export default class CareersPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			positions: false,
			showPositions: false,
		}

		this._mounted = false;
	}

	componentDidMount() {
		let vm = this;
		vm._mounted = true;

		Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vSfbHC5azLf060RnhAuZ67G_JUIFseILAje0RsB3_EEJvC3Srdm6djylcEwkgDS3r2mHIi7BflJGKXS/pub?output=csv',{
			download: true,
			header: true,
			complete: function(results) {
				var jobData = results.data
				if(vm._mounted){
					vm.setState({
						positions: jobData,
					});

					setTimeout(function() {
						if(vm._mounted) {
							vm.setState({
								showPositions: true,
							})
						}
					}, 50);
				}
			}
		})
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	render() {
		return (
			<React.Fragment>

				<ViewportWatcher  className="section careers-positions">
					<div className="highlight-wrap wrapper">
						<div className="positions-head vw-item from-top delay-1">
							<h2 className="head-title">Open positions</h2>
							<p className="head-subtitle vw-item from-top delay-2">
								If you wish to be part of great opportunities, lead amazing brands into success and take your <br className="only-web" />career into the next level check out our job listings.
							</p>
						</div>

						{this.state.positions ?
							<ul className={"positions-list" + (this.state.showPositions ? ' show' : '')}>
								{this.state.positions.map((position, nth) => (
									<li className={"list-position delay-"+(nth+1)} key={nth}>
										<div className="position-text">
											<h3 className="text-title">{position.title}</h3>
											<p className="text-subtitle">{position.location}{position.type && (', ' + position.type)}</p>
										</div>

										<div className="position-applications">
											{position.linkedin &&
												<Link className="applications-item linkedin" type="a" href={position.linkedin} target="_blank" rel="noopener">
												<i className="icon-linkedin"></i> Easy Apply
												</Link>
											}
										</div>
									</li>
								))}
							</ul>
						:
							<div className="positions-loader"><Loader /></div>
						}
						<div className="positions-highlight vw-item from-bottom delay-5">Life is short. <Link href="contact" params={{form: 'hi'}} className="highlight-cta">Join <strong>Us.</strong></Link></div>
					</div>
				</ViewportWatcher>

				<ViewportWatcher  className="section careers-features">
					<div className="wrapper">

						<div className="features-icons">
							<div className="icons-item vw-item from-top delay-6">
								<i className="item-icon icon-lamp" />
								<strong className="item-text">Cosy Office</strong>
							</div>

							<div className="icons-item vw-item from-top delay-7">
								<i className="item-icon icon-computer" />
								<strong className="item-text">Pro Tools</strong>
							</div>

							<div className="icons-item vw-item from-top delay-8">
								<i className="item-icon icon-gaming" />
								<strong className="item-text">Gaming</strong>
							</div>

							<div className="icons-item vw-item from-top delay-9">
								<i className="item-icon icon-location" />
								<strong className="item-text">Great Location</strong>
							</div>

							<div className="icons-item vw-item from-top delay-10">
								<i className="item-icon icon-stopwatch" />
								<strong className="item-text">Training</strong>
							</div>

							<div className="icons-item vw-item from-top delay-11">
								<i className="item-icon icon-coffeemaker" />
								<strong className="item-text">Social Zone</strong>
							</div>
						</div>
					</div>
				</ViewportWatcher>

			</React.Fragment>
		)
	}
}