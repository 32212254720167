import React from 'react'

// Deps
import ViewportWatcher from 'controllers/viewport-watcher'

// Partials
import Image from 'components/partials/image'
import Link from 'components/partials/link'

// Static Assets
import image_team_murat from 'assets/images/sections/about/team/murat.jpg'
import image_team_ceyla from 'assets/images/sections/about/team/ceyla.jpg'
import image_team_ferda from 'assets/images/sections/about/team/ferda.jpg'

const members = [
	{
		name: "Murat Yenilmez",
		title: "Founder",
		image: image_team_murat,
	},
	{
		name: "Ceyla Ensari",
		title: "Co-Founder & COO",
		image: image_team_ceyla
	},
	{
		name: "Ferda Tarakçıoğlu",
		title: "Deputy Managing Director",
		image: image_team_ferda
	}
]

export default class Team extends React.Component {

	render() {
		return (
			<ViewportWatcher className="section about-team">
				<div className="team-wrap wrapper">
					<ul className="team-list">
						{members.map((member, nth) => (
							<li className={"team-member vw-item from-top delay-" + ((nth + 1) * 1)} key={nth}>
								<Image className="member-image" src={member.image} />
								<div className="member-badge">
									<strong className={"badge-name vw-item from-top delay-" + (((nth + 1) * 1) + 1)}>{member.name}</strong>
									<p className={"badge-title vw-item from-top delay-" + (((nth + 1) * 1) + 2)}>{member.title}</p>
								</div>
							</li>
						))}

						<li className={"team-member vw-item from-top delay-" + (((members.length + 1) * 1) + 0)}>
							<Link className="team-join" href="careers">
								<div className="join-wrap">
									<div className={"join-text vw-item from-bottom delay-" + (((members.length + 1) * 1) + 1)}>
										<i className={"text-icon icon-plus vw-item from-bottom delay-" + (((members.length + 1) * 1) + 2)}></i>
										<strong className="text-title">Take me <br />to the list</strong>
									</div>
								</div>
							</Link>
						</li>
					</ul>
				</div>
			</ViewportWatcher>
		)

	}
}