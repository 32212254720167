import React from 'react'

// Deps
import ViewportWatcher from 'controllers/viewport-watcher'

// Sections
import Clients from 'components/sections/clients'
import Team from 'components/sections/team'

// Partials
//import Image from 'components/partials/image'

// Static Assets
//import image_section_careers from 'assets/images/sections/home/careers.png'

export default class AboutPage extends React.Component {
	render() {
		return (
			<React.Fragment>

				<ViewportWatcher  className="section about-intro" threshold="0">
					<div className="intro-wrap wrapper">
						<h2 className="intro-title vw-item from-left delay-2">Digital pioneers <br />Global marketers</h2>

						<div className="intro-description">
							<p className="vw-item from-top delay-4">We bring together geeks and creatives of all kinds together with a single purpose in mind: to craft marketing experiences.</p>
							<p className="vw-item from-top delay-5">Our team has worked together well for over a decade. Serial entrepreneurs ourselves, True partners have collectively built more than 25 companies.</p>
						</div>
					</div>
				</ViewportWatcher>

				<Clients count="12" />

				<Team />

			</React.Fragment>
		)
	}
}