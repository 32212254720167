import React from 'react';
//Deps
//import { imageLoad } from 'functions/helpers.js';

export default class Loader extends React.Component {
	render() {
		let className = this.props.className;
		
		if(this.props.small) { className += ' small'; }

		return (
			<div className={"loader-anim " + className}>
				<span className="anim-dot first"></span>
				<span className="anim-dot mid"></span>
				<span className="anim-dot last"></span>
			</div>
		)
	}
}

Loader.defaultProps = {
	className: '',
}