module.exports = {
	pages: {
		home: {
			path: "/",
			component: "Home",
			exact: true,
			linkTitle: "Home",
			//title: "SEO And Other Services",
			descriptiopn: "Lorem ipsum dolor sit amet.",
			meta: {
				keywords: "Optdcom, SEO"
			},
			// Bu şekilde head kısmına custom meta da eklenebilir.
			/*head: [
				{
					key: "meta",
					content: false,
					props: {
						name: "description",
						content: "Minoto, Sıfır Otomobilin Yeni Adresi"
					}
				},
				{
					key: "description",
					content: "Minoto, Sıfır Otomobilin Yeni Adresi",
					props: {}
				}
			],*/
		},
		about: {
			path: "/about",
			component: "About",
			exact: true,
			linkTitle: "About",
			title: "About Optdcom",
			descriptiopn: "Bringing marketing and technology together like never before.",
		},
		services: {
			path: "/services",
			component: "Services",
			exact: true,
			linkTitle: "Services",
			title: "Services",
			descriptiopn: "We are bringing marketing, technology and data together to grow your business. Get to know more about our services.",
		},
		servicedetail: {
			path: "/services/:service",
			component: "ServiceDetail",
			exact: true,
			linkTitle: "Service",
			title: "Service Detail",
			descriptiopn: "We are bringing marketing, technology and data together to grow your business. Get to know more about our services.",
		},
		careers: {
			path: "/careers",
			component: "Careers",
			exact: true,
			linkTitle: "Careers",
			title: "Careers",
			descriptiopn: "We’ve been waiting for you. Let’s work together.",
		},
		contact: {
			path: "/contact/:form?",
			component: "Contact",
			exact: false,
			linkTitle: "Contact",
			title: "Contact",
			descriptiopn: "Drop a line and we’ll be happy to get back to you",
		},
		notfound: {
			path: false,
			component: "NotFound",
			exact: false,
			linkTitle: "404",
			title: "This Page Was Lost",
			status: 404,
		}
	}
}