import React, { Component } from 'react'

// Controllers
import history from './controllers/history'
import Navigator from './controllers/navigator'

// Deps
import { Router } from 'react-router-dom'
import 'core-js/stable';
import 'regenerator-runtime/runtime';

class App extends Component {
	render() {
		return (
			<Router history={history}>
				<Navigator />
			</Router>
		);
	}
}

export default App;
