import React from 'react'
import moduleName from 'module'
import '../../assets/css/rotatelogo.css'
import bs from '../../assets/images/blogspin.svg'
import bsdark from '../../assets/images/blogspindark.svg'
import { ReactSVG } from 'react-svg'

// Deps
import { connect } from 'react-redux'
import throttle from 'lodash/throttle';

// Partials
import Link from 'components/partials/link'
import { isExact } from 'functions/helpers'

const mapStateToProps = state => {
    return {
        mobile: state.generic.mobile,
        currentPage: state.generic.currentPage,
    };
};

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            menuActive: false,
            menuShow: false,
            pageKey: props.currentPage.key,
        }

        this.toggleMenu = this.toggleMenu.bind(this);

        this.headerTimeout = false;
    }

    toggleMenu() {
        this.setState({ menuOpen: !this.state.menuOpen });
    }

    componentDidUpdate(prevProps, prevState) {
        let vm = this;
        if (this.props.mobile && prevState.menuOpen !== this.state.menuOpen) {
            if (vm.headerTimeout !== false) {
                clearTimeout(vm.headerTimeout);
            }

            if (vm.state.menuOpen) {
                vm.setState({ menuActive: true });
                vm.headerTimeout = setTimeout(function () {
                    vm.headerTimeout = false;
                    vm.setState({ menuShow: true })
                }, 30);
            } else {
                vm.setState({ menuShow: false });
                vm.headerTimeout = setTimeout(function () {
                    vm.headerTimeout = false;
                    vm.setState({ menuActive: false })
                }, 1010);
            }
        } else if (prevProps.mobile !== vm.props.mobile) {
            if (vm.headerTimeout !== false) {
                clearTimeout(vm.headerTimeout);
            }
            vm.setState({
                menuOpen: false,
                menuActive: false,
                menuShow: false,
            })
        } else if (prevProps.currentPage.key !== vm.props.currentPage.key) {
            setTimeout(function () {
                vm.setState({ pageKey: vm.props.currentPage.key })
            }, 600);
            if (vm.props.mobile) {
                vm.setState({ menuOpen: false });
            }
        }
    }

    render() {

        return (<header className={"section header pg-" + this.state.pageKey} >
            <div className="header-wrap wrapper wide" > {
                this.props.mobile &&
                <button className={"header-menubtn" + (this.state.menuOpen ? ' open' : '')}
                    type="button"
                    onClick={this.toggleMenu} >
                    <div className="menubtn-inner" >
                        <span> </span> <span> </span> <span > </span> <span> </span> </div>
                </button>
            }

                <Link className="header-logo"
                    href="home" >
                    <Logo className="logo-image" />
                </Link>

                <nav className={"header-nav" + (this.state.menuActive ? ' active' : '') + (this.state.menuShow ? ' show' : '')} >
                    <div className="nav-item" >
                        <Link className="item-link"
                            navLink href="about" />
                    </div> {
                        /*
                                                   <div className="nav-item">
                                                       <Link className={"item-link" + (this.props.currentPage.key === 'servicedetail' ? ' active' : '')} navLink href="services" />
                                                   </div>
                                                   */
                    } <SubMenu href="services"
                        active={this.props.currentPage.key === 'servicedetail'} >
                        <Link navLink className="item-link"
                            href="servicedetail"
                            params={
                                { service: 'performance-marketing' }} >
                            Performance  Marketing </Link>
                        <Link navLink className="item-link"
                            href="servicedetail"
                            params={
                                { service: 'seo' }} >
                            Search  Engine  Optimization </Link> <Link navLink className="item-link"
                            href="servicedetail"
                            params={
                                { service: 'strategy' }} >
                            Strategy </Link> <Link navLink className="item-link"
                            href="servicedetail"
                            params={
                                { service: 'data-and-analytics' }} >
                            Data  &  Analytics </Link> <Link navLink className="item-link"
                            href="servicedetail"
                            params={
                                { service: 'digital-media-buying' }} >
                            Digital  Media  Buying </Link>
                        <Link navLink className="item-link"
                            href="servicedetail"
                            params={
                                { service: 'programmatic-media-buying' }} >
                            Programmatic  Media  Buying </Link> </SubMenu>
                    <div className="nav-item" >
                        <Link className="item-link"
                            navLink href="careers" />
                    </div>
                    <div className="nav-item" >
                        <Link className="item-link"
                            navLink href="contact" />
                    </div>
                    {/*<div className="only-mobile  nav-item" >*/}
                    {/*    <a className="item-link" id="circle" target="_blank" href="/levitate" >*/}
                    {/*        <p className="nav-item"> Levitate Blog</p>*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                         
                    {/*<a className="item-link only-web" id="circle" target="_blank" href="/levitate" >*/}
                    {/*    <ReactSVG className="logo-image blog-logo"  src={ (this.state.pageKey ==='home' | this.state.pageKey ==='careers' )? bs : bsdark} />*/}
                    {/*</a>*/}

                </nav> </div> </header>
        )
    }
}

class SubMenuRaw extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            show: false,
        }

        this.handleEnter = throttle(this.handleEnter.bind(this), 100);
        this.handleLeave = throttle(this.handleLeave.bind(this), 100);

        this.timer = false;
    }

    handleEnter() {
        let vm = this;

        if (vm.timer) { clearTimeout(vm.timer); }

        vm.setState({ active: true });
        vm.timer = setTimeout(function () {
            vm.setState({ show: true });
            this.timer = false;
        }, 30)
    }

    handleLeave() {
        let vm = this;

        if (vm.timer) { clearTimeout(vm.timer); }

        vm.setState({ show: false });
        vm.timer = setTimeout(function () {
            vm.setState({ active: false });
            this.timer = false;
        }, 200)
    }

    render() {
        return (<div className={"nav-item" + (!this.props.mobile ? ' has-submenu' : '')}
            onMouseEnter={this.handleEnter}
            onMouseLeave={this.handleLeave} >
            <Link className={"item-link" + (this.props.active ? ' active' : '')}
                navLink href={this.props.href} /> {
                !this.props.mobile &&
                <div className={"item-submenu" + (this.state.active ? ' active' : '') + (this.state.show ? ' show' : '')} >
                    <ul className="submenu-content" > {
                        React.Children.map(this.props.children, (child, nth) => (
                            <li className="content-item"
                                key={nth} > { child}
                            </li>
                        ))
                    } </ul> </div>
            } </div>
        )
    }
}



let SubMenu = connect(mapStateToProps)(SubMenuRaw);

export default connect(mapStateToProps)(Header);

class Logo extends React.Component {
    render() {
        return (<svg className={this.props.className}
            version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 299.6 290.4" style={{ enableBackground: 'new 0 0 299.6 290.4',  transform: 'scale(1.3)' }} xmlSpace="preserve" >
            <g>
                <defs >
                    <rect id="SVGID_1_" width="299.6"
                        height="290.4" />
                </defs> <clipPath id="SVGID_2_" >
                    <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                </clipPath> <path className="st0" d="M180.9,158.5h-0.1c-2.5,21.1-20.4,37.5-42.2,37.5c-23.5,0-42.5-19.1-42.5-42.5s19.1-42.5,42.5-42.5
		c8.9,0,17.2,2.7,24,7.4l-0.1-33.3l18.2-0.1l0.2,64c0.2,1.5,0.2,2.9,0.2,4.5c0,1.4-0.1,2.8-0.2,4.2L180.9,158.5z M255.6,208h-18.2
		v40.9l-24-40.9h-18.2v74.8h42.3h19.9h42.3L255.6,208z M182.3,247.9c0,23.5-19,42.5-42.5,42.5s-42.5-19-42.5-42.5
		c0-23.5,19-42.5,42.5-42.5S182.3,224.4,182.3,247.9 M158.2,242.3h-12.9v-12.9h-11v12.9h-12.9v11h12.9v12.9h11v-12.9h12.9V242.3z
		 M272.6,0.5h-81.8v18.2h31.8v64.6h18.2V18.7h31.8V0.5z M139.7,0c-23.5,0-42.5,19-42.5,42.5c0,0.7,0.1,1.3,0.1,2H97v66.4h18.2V77.3
		c6.9,4.9,15.3,7.8,24.4,7.8c23.5,0,42.5-19,42.5-42.5C182.2,19,163.2,0,139.7,0 M63.7,5.2c-30.5,33.1-47,50.9-56.1,60.5
		c1.7,2.6,3.6,4.9,5.8,7.1c13.3-14.2,38-40.9,57.8-62.4C68.8,8.5,66.3,6.7,63.7,5.2 M74,13.3C42.3,47.7,25.4,65.9,16.4,75.5
		c2.3,1.8,4.7,3.4,7.3,4.8C37.1,66,60.4,40.8,79.4,20.2C77.8,17.7,76,15.4,74,13.3 M81.4,23.9C53.1,54.7,36.9,72.1,27.6,82.1
		c3,1.2,6.2,2,9.4,2.5c12.7-13.7,31.4-33.8,47.7-51.5C84,29.9,82.8,26.8,81.4,23.9 M42.2,85c0.4,0,0.7,0.1,1,0.1
		c23.5,0,42.5-19,42.5-42.5c0-1.5-0.1-2.9-0.2-4.4C65,60.5,51.3,75.3,42.2,85 M50.1,0.6C25.9,26.9,11.1,43,1.9,52.7
		c0.8,3.3,2,6.5,3.5,9.4c13.1-14,35.7-38.4,54.4-58.7C56.8,2.1,53.5,1.2,50.1,0.6 M45.1,0.1c-0.6,0-1.3-0.1-1.9-0.1
		C19.7,0,0.7,19,0.7,42.5c0,1.8,0.1,3.5,0.3,5.2C12.9,34.9,29.8,16.7,45.1,0.1 M61.5,253.4c-2.5,8.1-10.1,13.9-19,13.9
		c-11,0-19.9-8.9-19.9-19.9c0-11,8.9-19.9,19.9-19.9c9.2,0,17,6.3,19.2,14.8h23c-2.5-21.1-20.4-37.5-42.2-37.5
		C19,204.8,0,223.9,0,247.4c0,23.5,19,42.5,42.5,42.5c21.5,0,39.2-15.9,42.1-36.6H61.5z"/>
                <path className="st1" d="M62.4,153.6c0,11-8.9,19.9-19.9,19.9c-11,0-19.9-8.9-19.9-19.9c0-11,8.9-19.9,19.9-19.9
		C53.5,133.7,62.4,142.6,62.4,153.6 M231.7,133.7c-11,0-19.9,8.9-19.9,19.9c0,11,8.9,19.9,19.9,19.9c11,0,19.9-8.9,19.9-19.9
		C251.6,142.6,242.7,133.7,231.7,133.7 M138.9,133.7c-11,0-19.9,8.9-19.9,19.9c0,11,8.9,19.9,19.9,19.9s19.9-8.9,19.9-19.9
		C158.8,142.6,149.9,133.7,138.9,133.7"/></g> </svg>
        )
    }
}