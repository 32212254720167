import React from 'react'

// Deps
import ViewportWatcher from 'controllers/viewport-watcher'

// Partials
import Link from 'components/partials/link'

export default class Footer extends React.Component {
	render() {
		return (
			<ViewportWatcher tag="footer" className="section footer">
				<div className="wrapper">
					<div className="footer-top">
						<div className="footer-highlight vw-item from-bottom delay-1">
							<h2 className="highlight-title">Don't be shy <br />say <span className="title-highlight">hi.</span></h2>
							<Link type="mail" className="highlight-link link" href="hi@optdcom.net" />
						</div>

						<div className="footer-nav">
							<address className="nav-address vw-item from-bottom delay-3">
								<strong className="address-title">İstanbul</strong>
								<p className="address-text">
									Aytar Cd. Fecri Ebcioğlu Sk. <br />
									4/3 Levent, Istanbul, TR <br />
									<Link type="tel" href="+90 850 460 07 49" />
								</p>
							</address>

							<address className="nav-address vw-item from-bottom delay-6">
								<strong className="address-title">London</strong>
								<p className="address-text">
									1-2 Charterhourse Mews <br />
									London, EC1M 6BB, UK <br />
									<Link type="tel" href="+44 20 7504 1323" />
								</p>
							</address>

							<nav className="nav-links vw-item from-bottom delay-9">
								<div className="links-item">
									<Link href="about" />
								</div>
								<div className="links-item">
									<Link href="services" />
								</div>
								<div className="links-item">
									<Link href="careers" />
								</div>
								<div className="links-item">
									<Link href="contact" />
								</div>
							</nav>

							<nav className="nav-links vw-item from-bottom delay-12">
								<div className="links-item">
									<Link type="a" href="https://www.facebook.com/Optdcom/" target="_blank" rel="noopener">Facebook</Link>
								</div>
								<div className="links-item">
									<Link type="a" href="https://twitter.com/optdcom" target="_blank" rel="noopener">Twitter</Link>
								</div>
								<div className="links-item">
									<Link type="a" href="https://tr.linkedin.com/company/optdcom" target="_blank" rel="noopener">LinkedIn</Link>
								</div>
							</nav>
							<nav className="nav-links vw-item from-bottom delay-12"
								>
									<a href="https://www.google.com/partners/agency?id=6663645223" target="_blank">
										<img style={{height: "128px"}}
											src="https://www.gstatic.com/partners/badge/images/2022/PremierBadgeClickable.svg"/>
									</a>
							</nav>
						</div>
					</div>

					<div className="footer-bottom vw-item from-bottom delay-15">
						<div className="footer-copyright">
							Copyright © 2021 Optdcom.net
						</div>
					</div>
				</div>
			</ViewportWatcher>
		)
	}
}