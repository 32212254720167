
import NotFound from 'pages/notfound'
import React, { Component } from 'react'

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    render() {
        return (
            this.state.hasError
                ?   <div style={{ textAlign: 'center', verticalAlign: 'center', height: '100vh', backgroundColor: '#333' }}>
                        <h1 style={{ paddingTop: '25%', color: 'red' }}>SOMETHING WENT WRONG !</h1>
                    </div>
                : this.props.children
        )
    }

}

