import React from 'react'

// Deps
import ViewportWatcher from 'controllers/viewport-watcher'
import serviceData from 'data/services'

// Sections
import Clients from 'components/sections/clients'

// Partials
import Btn from 'components/partials/btn'

// Static Assets


export default class ServicesPage extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			data: false,
		}

		this.updateData = this.updateData.bind(this);
	}

	componentDidMount() {
		this.updateData();
	}

	componentDidUpdate(prevProps) {
		if(this.props.match.params.service !== prevProps.match.params.service){
			this.updateData();
		}
	}

	updateData() {
		let data = serviceData[this.props.match.params.service];

		if(data){
			this.setState({data: data});
		}
		else {
			console.log('no');
		}
	}

	render() {
		if(this.state.data){
			return (
				<React.Fragment>

					<ViewportWatcher  className="section services-detail-sections" key={this.props.match.params.service}>
						<div className="wrapper sections-wrap">
							{this.state.data.sections.map((section, nth) => (
								<div className={"sections-section vw-item from-top delay-"+nth} key={nth}>
									<i className={'section-icon icon-' + section.icon}></i>
									<h2 className="section-title">{section.title}</h2>
									<div className="section-content" dangerouslySetInnerHTML={{__html: section.content}}></div>
								</div>
							))}
						</div>
					</ViewportWatcher>

					<Clients tools count={12} mobileCount={6} />

					<ViewportWatcher  className="section hire">
						<div className="wrapper hire-wrap">
							<div className="hire-text">
								<strong>It is time to <br className="only-mobile" />hire Optdcom</strong> <br />
								Let's work together!
							</div>

							<Btn tag="link" href="contact" className="hire-cta" arrow wide>Hire now</Btn>
						</div>
					</ViewportWatcher>

				</React.Fragment>
			)
		}
		else { return false; }
	}
}