import React from 'react'

// Deps
import ViewportWatcher from 'controllers/viewport-watcher'

// Pages
import NotFound from 'pages/notfound'

// Sections
import { serializeArray } from 'functions/helpers'

// Partials
import Image from 'components/partials/image'
import Link from 'components/partials/link'
import Btn from 'components/partials/btn'
import { InputForm, FormInput } from 'components/partials/forms'
//import Loader from 'components/partials/loader'

// Static Assets
import image_branch_istanbul from 'assets/images/sections/contact/branch-istanbul.jpg'
import image_branch_london from 'assets/images/sections/contact/branch-london.jpg'

import image_branch_istanbul_mobile from 'assets/images/sections/contact/branch-istanbul-mobile.jpg'
import image_branch_london_mobile from 'assets/images/sections/contact/branch-london-mobile.jpg'

const formTypes = [
	'inquiry',
	'hi',
]

export default class ContactPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			formSubmitting: false,
			formComplete: false,
			formType: this.getFormType(),
			formFlicker: false,
		}

		this.contactSubmit = this.contactSubmit.bind(this);
		this.getFormType = this.getFormType.bind(this);
	}

	componentDidUpdate(prevProps) {
		let vm = this;

		if(prevProps.match.params.form !== vm.props.match.params.form) {
			vm.setState({ formFlicker: true });
			setTimeout(() => {
				vm.setState({ formType: vm.getFormType(), formFlicker: false });
			}, 410);
		}
	}

	getFormType() {
		let index = formTypes.indexOf(this.props.match.params.form);
		if(index === -1){
			if(this.props.match.params.form) {
				index = false;
			}
			else {
				index = formTypes[0];
			}
		}
		else {
			index = formTypes[index];
		}
		return index;
	}

	contactSubmit(event) {
		let vm = this;
		console.log(serializeArray(event.target));
		this.setState({
			formSubmitting: true,
		})

		setTimeout(function() {
			vm.setState({
				formComplete: true,
				formSubmitting: false,
			})
		}, 3000);
	}

	render() {
		if(this.state.formType) {
			return (
				<React.Fragment>

					<ViewportWatcher  className="section contact-nav">
						<div className="wrapper vw-item from-top delay-2">
							<div className="nav-sections">
								<Link disabled={this.state.formType === 'inquiry'} href="contact" className={"sections-section inquiry" + (this.state.formType === 'inquiry' ? ' active' : '')}>
									<strong className="section-title">Hire Optdcom</strong>
									<p className="section-subtitle">If you’re looking for a partner with a holistic digital approach, <br />we’re here for you.</p>
								</Link>
								<Link disabled={this.state.formType === 'hi'} href="contact" params={{form: 'hi'}} className={"sections-section hi" + (this.state.formType === 'hi' ? ' active' : '')}>
									<strong className="section-title">Just Say Hi!</strong>
									<p className="section-subtitle">If you’re looking to talk to us, feel free to drop a line.</p>
								</Link>
							</div>
						</div>
					</ViewportWatcher>

					<ViewportWatcher  className="section contact-content">
						<div className="content-wrap wrapper">
							<InputForm
								className="content-form vw-item from-left delay-1"
								onSubmit={this.contactSubmit}>
								<div className={"form-innerwrap" + (this.state.formFlicker ? ' flicker' : '')}>
									<FormInput
										disabled={this.state.formSubmitting}
										className="form-field vw-item from-left delay-2"
										name="name"
										label="Full Name"
										placeholder="Thomas Shelby"
										validation={{ required: "Please enter your name.", fullName: "Please enter a valid name." }}
										type="text" />

									<FormInput
										disabled={this.state.formSubmitting}
										className="form-field vw-item from-left delay-3"
										name="company"
										label="Company Name"
										placeholder="Shelby Corp."
										validation={{
											minLength: [undefined, 3],
											text: "Please enter a valid company name."
										}}
										type="text" />

									<FormInput
										disabled={this.state.formSubmitting}
										className="form-field vw-item from-left delay-4"
										name="job"
										label="Job Title"
										placeholder="Entrepreneur"
										validation={{
											minLength: [undefined, 3],
											text: "Please enter a valid job title."
										}}
										type="text" />

									<FormInput
										disabled={this.state.formSubmitting}
										className="form-field vw-item from-left delay-5"
										name="email"
										label="Mail"
										placeholder="Thomas@shelby.co.uk"
										validation={{
											required: "Please enter your email address.",
											email: "Please enter a valid email address."
										}}
										type="text" />

									{this.state.formType === 'hi' &&
										<React.Fragment>
											<FormInput
												disabled={this.state.formSubmitting}
												className="form-field vw-item from-left delay-6"
												name="phone"
												label="Phone"
												placeholder="+44 (---) --------"
												mask="%%%%%%%%%%%%%%"
												validation={{
													required: "Please enter your phone number",
													minLength: ["Your phone number must have at least {length} digits.", 10]
												}}
												type="number" />
											<FormInput
												disabled={this.state.formSubmitting}
												className="form-field vw-item from-left delay-7"
												name="message"
												label="Message"
												placeholder="Tell us about your project"
												validation={{
													minLength: ["Your message must have at least {length} characters.", 10],
													minWords: ["Your message must have at least {length} words.", 5],
												}}
												type="textarea" />
										</React.Fragment>
									}

									<div className={"form-submitwrap vw-item from-left delay-" + (this.state.formType === 'hi' ? '8' : '6')}>
										<Btn
											className={"form-submit" + (this.state.formComplete ? ' complete' : '')}
											wide
											solid
											leftIcon={this.state.formComplete ? 'check' : 'message'}
											disabled={this.state.formSubmitting}
											{...{loading: (this.state.formSubmitting ? true : undefined)}}
											type="submit">
											{this.state.formComplete ? 'Complete!' : 'Submit'}
										</Btn>
									</div>
								</div>
							</InputForm>

							<div className="content-alts vw-item from-left delay-3">
								<h2 className="alts-title">Fill in the form, or, <br />if you prefer,</h2>
								<div className="alts-optwrap vw-item from-left delay-4">
									<Btn
										className="alts-opt"
										leftIcon="mail"
										tag="link"
										type="mail"
										wide
										href="hi@optdcom.net">
										Send Us a Mail</Btn>
								</div>
								<div className="alts-optwrap vw-item from-left delay-5">
									<Btn
										className="alts-opt"
										leftIcon="phone"
										tag="link"
										type="tel"
										wide
										href="+90 850 460 07 49">
										Call Us*</Btn>
								</div>
								<div className="alts-note vw-item from-left delay-6">*Working hours are Monday - Friday <br />from 09:00 to 18:00</div>
							</div>
						</div>
					</ViewportWatcher>

					<ViewportWatcher  className="section contact-branches">
						<div className="wrapper">
							<ul className="branches-list">
								<li className="list-branch vw-item from-bottom delay-2">
									<Image
										src={image_branch_istanbul}
										mobile={image_branch_istanbul_mobile}
										alt="Optdcom İstanbul" />

									<div className="branch-info vw-item from-bottom delay-3">
										<div className="info-text">
											<h3 className="text-title">İstanbul</h3>
											<p className="text-address">Aytar Cd. Fecri Ebcioğlu Sk. <br />4/3 34582 Levent, Istanbul, TR</p>
										</div>
										<Link
											className="info-cta"
											type="a"
											href="https://goo.gl/maps/R1QEfJndhpDJNSaKA"
											target="_blank"
											rel="noopener">
											<span>Get <br />Directions</span>
											<i className="cta-arrow icon-arrow-right"></i>
										</Link>

									</div>
								</li>

								<li className="list-branch vw-item from-bottom delay-4">
									<Image
										src={image_branch_london}
										mobile={image_branch_london_mobile}
										alt="Optdcom London" />

									<div className="branch-info vw-item from-bottom delay-5">
										<div className="info-text">
											<h3 className="text-title">London</h3>
											<p className="text-address">1-2 Charterhourse Mews <br />London, EC1M 6BB, UK</p>
										</div>
										<Link
											className="info-cta"
											type="a"
											href="https://goo.gl/maps/YLmNWCFCSD9c35PN6"
											target="_blank"
											rel="noopener">
											<span>Get <br />Directions</span>
											<i className="cta-arrow icon-arrow-right"></i>
										</Link>

									</div>
								</li>
							</ul>
						</div>
					</ViewportWatcher>
				</React.Fragment>
			)
		}
		else {
			return ( <NotFound /> )
		}
	}
}