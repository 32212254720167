import React from 'react'

// Deps
import ViewportWatcher from 'controllers/viewport-watcher'
import shuffle from 'lodash/shuffle'
import slice from 'lodash/slice'
import {connect} from 'react-redux'

// Partials
import Image from 'components/partials/image'

// Static Assets
import image_tool_ahrefs from 'assets/images/sections/tools/ahrefs.png'
import image_tool_screamingfrog from 'assets/images/sections/tools/screamingfrog.png'
import image_tool_keyword from 'assets/images/sections/tools/keyword.png'
import image_tool_majestic from 'assets/images/sections/tools/majestic.png'
import image_tool_onc from 'assets/images/sections/tools/onc.png'
import image_tool_searchmetrics from 'assets/images/sections/tools/searchmetrics.png'
import image_tool_sem from 'assets/images/sections/tools/sem.png'
import image_tool_seo from 'assets/images/sections/tools/seo.png'
import image_tool_similar from 'assets/images/sections/tools/similar.png'
import image_tool_stat from 'assets/images/sections/tools/stat.png'
import image_tool_super from 'assets/images/sections/tools/super.png'
import image_tool_url from 'assets/images/sections/tools/url.png'

import image_brand_anadolu from 'assets/images/sections/clients/anadolu.png'
import image_brand_avansas from 'assets/images/sections/clients/avansas.png'
import image_brand_fibabanka from 'assets/images/sections/clients/fibabanka.png'
import image_brand_godiva from 'assets/images/sections/clients/godiva.png'
import image_brand_mavi from 'assets/images/sections/clients/mavi.png'
import image_brand_mcdonalds from 'assets/images/sections/clients/mcdonalds.png'
import image_brand_pronet from 'assets/images/sections/clients/pronet.png'
import image_brand_tatilbudur from 'assets/images/sections/clients/tatilbudur.png'
import image_brand_terrapizza from 'assets/images/sections/clients/terrapizza.png'
import image_brand_tween from 'assets/images/sections/clients/tween.png'
import image_brand_urbancare from 'assets/images/sections/clients/urbancare.png'
import image_brand_yatas from 'assets/images/sections/clients/yatas.png'
import image_brand_alisgidis from 'assets/images/sections/clients/ag.png'
import image_brand_akbank from 'assets/images/sections/clients/akbank.png'
import image_brand_aydem from 'assets/images/sections/clients/aydem.png'
import image_brand_chicco from 'assets/images/sections/clients/chicco.png'
import image_brand_estar from 'assets/images/sections/clients/estar.png'
import image_brand_fibas from 'assets/images/sections/clients/fibas.png'
import image_brand_fonmap from 'assets/images/sections/clients/fonmap.png'
import image_brand_gedik from 'assets/images/sections/clients/gedik.png'
import image_brand_hdifiba from 'assets/images/sections/clients/hdifiba.png'
import image_brand_idefix from 'assets/images/sections/clients/idefix.png'
import image_brand_kia from 'assets/images/sections/clients/kia.png'
import image_brand_mm from 'assets/images/sections/clients/mediam.png'
import image_brand_nivea from 'assets/images/sections/clients/nivea.png'
import image_brand_ozdilek from 'assets/images/sections/clients/ozdilek.png'
import image_brand_samsung from 'assets/images/sections/clients/samsung.png'
import image_brand_skc from 'assets/images/sections/clients/skc.png'
import image_brand_suwen from 'assets/images/sections/clients/suwen.png'
import image_brand_tgm from 'assets/images/sections/clients/tgm.png'
import image_brand_thy from 'assets/images/sections/clients/thy.png'
import image_brand_tosyali from 'assets/images/sections/clients/tosyali.png'
import image_brand_vanity from 'assets/images/sections/clients/vanity.png'

const brandsList = [
	{ title: "Anadolu Sağlık", image: image_brand_anadolu },
	{ title: "Avansas", image: image_brand_avansas },
	{ title: "Fibabanka", image: image_brand_fibabanka },
	{ title: "Godiva", image: image_brand_godiva },
	{ title: "Mavi", image: image_brand_mavi },
	{ title: "McDonald's", image: image_brand_mcdonalds },
	{ title: "Pronet", image: image_brand_pronet },
	{ title: "TatilBudur", image: image_brand_tatilbudur },
	{ title: "Terra Pizza", image: image_brand_terrapizza },
	{ title: "Damat Tween", image: image_brand_tween },
	{ title: "UrbanCare", image: image_brand_urbancare },
	{ title: "Yataş", image: image_brand_yatas },
	{ title: "Alış Gidiş", image: image_brand_alisgidis },
	{ title: "Akbank", image: image_brand_akbank },
	{ title: "Aydem Enerji", image: image_brand_aydem },
	{ title: "Chicco", image: image_brand_chicco },
	{ title: "E-Star", image: image_brand_estar },
	{ title: "Fibasigorta", image: image_brand_fibas },
	{ title: "Fonamp", image: image_brand_fonmap },
	{ title: "Gedişk Yatırım", image: image_brand_gedik },
	{ title: "HDI | Fibaemeklilik", image: image_brand_hdifiba },
	{ title: "Idefix", image: image_brand_idefix },
	{ title: "KIA", image: image_brand_kia },
	{ title: "MediaMarkt", image: image_brand_mm },
	{ title: "Nivea", image: image_brand_nivea },
	{ title: "Özdilek", image: image_brand_ozdilek },
	{ title: "Samsung", image: image_brand_samsung },
	{ title: "Kardeş Elektrik", image: image_brand_skc },
	{ title: "SUWEN", image: image_brand_suwen },
	{ title: "Touch Güzellik Merkezi", image: image_brand_tgm },
	{ title: "Turkish Airlines", image: image_brand_thy },
	{ title: "Tosyalı Holding", image: image_brand_tosyali },
	{ title: "VANITY", image: image_brand_vanity },
]

const toolsList = [
	{ title: "ahrefs", image: image_tool_ahrefs },
	{ title: "Screaming Frog", image: image_tool_screamingfrog },
	{ title: "Keyword Tool", image: image_tool_keyword },
	{ title: "Majestic", image: image_tool_majestic },
	{ title: "OnCrawl", image: image_tool_onc },
	{ title: "Searchmetrics", image: image_tool_searchmetrics },
	{ title: "Semrush", image: image_tool_sem },
	{ title: "SEOmonitor", image: image_tool_seo },
	{ title: "SimilarWeb", image: image_tool_similar },
	{ title: "STAT Search Analytics", image: image_tool_stat },
	{ title: "Supermetrics", image: image_tool_super },
	{ title: "URL Profiler", image: image_tool_url },
]

const mapStateToProps = state => {
	return {
		mobile: state.generic.mobile,
	};
};

class Clients extends React.Component {

	constructor(props){
		super(props);

		this.availableBrands = (props.tools ? shuffle(toolsList) : shuffle(brandsList));

		this.init = this.init.bind(this);
		this.getNextBrand = this.getNextBrand.bind(this);
		this.changeRandomBrand = this.changeRandomBrand.bind(this);

		this.clientElems = [];
		this.interval = null;

		this._mounted = false;
	}

	componentDidMount() {
		this._mounted = true;
		this.init();
	}

	componentDidUpdate(prevProps) {
		if(prevProps.mobile !== this.props.mobile) {
			if(this.interval !== null) { clearInterval(this.interval); this.interval = null; }
			this.init();
		}
	}

	componentWillUnmount() {
		this._mounted = false;
		clearInterval(this.interval);
	}

	init() {
		this.clientElems = shuffle(this.clientElems);
		for(let k = 0; k < this.clientElems.length; k++) {
			this.changeRandomBrand();
		}

		let count = (this.props.mobile ? this.props.mobileCount : this.props.count);

		if(this._mounted && this.availableBrands.length > count){
			this.interval = setInterval(this.changeRandomBrand, 3000);
		}
	}

	getNextBrand() {
		let elem = this.availableBrands.shift();
		this.availableBrands.push(elem);
		return elem;
	}

	changeRandomBrand() {
		let client = this.clientElems.shift();
		this.clientElems.push(client);
		client.getData();
	}

	render() {
		this.clientElems = [];
		return (
			<ViewportWatcher  className={"section highlight-clients " + (this.props.home ? 'home-highlight' : 'standalone') + (this.props.tools ? ' tools' : '')}>
				<div className="clients-wrap list-wrap wrapper">
					{this.props.showTitle &&
						<h2 className="clients-title vw-item from-left delay-1">Here are some of <br />our most recent <strong>clients!</strong></h2>
					}

					<div className="clients-list">
						{slice(this.availableBrands, 0, parseInt((this.props.mobile ? this.props.mobileCount : this.props.count))).map((brand, nth) => (
							<React.Fragment key={nth+'_'+(this.props.mobile ? 'm' : 'd')}>
								<ClientElem onImageRequest={this.getNextBrand} nth={nth} ref={(ref) => { if(ref !== null) { this.clientElems.push(ref); return true; } }} />
								{(((this.props.mobile && nth % 2 === 1) || (!this.props.mobile && nth % 4 === 3)) ?
									<div className="list-clear"></div>
									: '')
								}
							</React.Fragment>
						))}
					</div>
				</div>
			</ViewportWatcher>
		)

	}
}

export default connect(mapStateToProps)(Clients);

Clients.defaultProps = {
	showTitle: false,
	count: 8,
	mobileCount: 6,
	home: false,
}

class ClientElem extends React.Component {

	constructor(props) {
		super(props);

		this.state  = {
			curImage: false,
			nextImage: false,
			change: false,
		}

		this.timer = false;

		this._mounted = false;
	}

	componentDidMount() {
		this._mounted = true;
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevState.nextImage && prevState.nextImage !== this.state.nextImage) {

		}
	}

	getData() {
		let vm = this;
		if(vm._mounted){
			let newImage = vm.props.onImageRequest();
			if(vm.state.curImage){
				vm.setState({
					nextImage: newImage,
				});

				setTimeout(function() {
					if(vm._mounted){
						vm.setState({
							change: true,
						});

						setTimeout(function() {
							if(vm._mounted){
								vm.setState({
									curImage: vm.state.nextImage,
								});

								setTimeout(function() {
									if(vm._mounted){
										vm.setState({
											change: false,
										});
									}
								}, 50);
							}
						}, 3100);
					}
				}, 50);
			}
			else {
				vm.setState({
					curImage: newImage,
					change: false,
				});
			}

			/*setTimeout(function() {
				vm.getData();
			}, Math.floor(Math.random() * 10000) + 4000);*/
		}
	}

	render() {
		return (
			<div className={"list-client vw-item from-bottom delay-"+this.props.nth + (this.state.change ? ' change' : '')}>
				<Image
					bg
					contain
					className="client-image current"
					src={this.state.curImage.image}
					alt={this.state.curImage.title} />

				<Image
					bg
					contain
					className="client-image next"
					src={this.state.nextImage.image}
					alt={this.state.nextImage.title} />
			</div>
		)
	}

}
