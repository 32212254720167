import React from 'react';
//Deps
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import InView from 'react-intersection-observer'

export default class ViewportWatcher extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			visible : false,
		}

		this.changed = debounce(this.changed, parseInt(this.props.delay));
	}
	changed(status){
		if(this.props.triggerOnce){
			if(status !== false){
				this.setState({visible: true})
			}
		}
		else{
			this.setState({visible: status});
		}

		if(this.props.onChange !== false){
			this.props.onChange(status);
		}

		if(this.props.onEnter !== false && status){
			this.props.onEnter();
		}
		else if(this.props.onLeave !== false){
			this.props.onLeave();
		}
	}

	render() {
		let props = omit(this.props, ['className', 'onChange', 'onEnter', 'onLeave', 'triggerOnce', 'threshold', 'children', 'tag']);

		return (
			<InView
           		{...props}
				className={this.props.className + ' viewport-watcher' + (this.state.visible ? ' in-viewport' : '')}
				as={this.props.tag}
				threshold={parseFloat(this.props.threshold)}
				onChange={(e)=>this.changed(e)}>
					{this.props.children}
			</InView>
		);
	}
}

ViewportWatcher.defaultProps = {
	className: '',
	triggerOnce: true,
	onChange: false,
	onEnter: false,
	onLeave: false,
	tag: 'section',
	delay: 0,
	threshold: 0.1,
};