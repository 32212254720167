module.exports = {
	titlePostFix: 'Optdcom',
	mobileBreakPoint: 960,
	mapsAPIkey: "AIzaSyDncyEm2ApXXZ0x8D8QNdqtj8S3j2Sn-4M",
	mapsTheme: false,

	social: {
		instagramURL: "http://www.instagram.com/optdcom",
		youtubeURL: "https://www.youtube.com/optdcom",
		facebookURL: "http://www.facebook.com/optdcom",
		twitterURL: "http://www.twitter.com/optdcom",
		linkedinURL: "https://www.linkedin.com/company/optdcom",
	}
}