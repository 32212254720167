import React from 'react'

// Sections
import Header from 'components/sections/header'
import Footer from 'components/sections/footer'
import Cover from 'components/sections/cover'

// Partials

// Modals

// Controllers
import DomWatcher from 'controllers/dom-watcher'

// Deps
import { Route, matchPath, Switch, Redirect } from 'react-router-dom'
import history from 'controllers/history'
import { setTitle, setMeta, setHead, setDescription } from 'controllers/head'
import routes from 'data/routes'
import store from "data/store";
import { setPage } from "data/store.generic";
import extend from "lodash/extend";

// Pages
import Home from 'pages/home'
import About from 'pages/about'
import Services from 'pages/services'
import ServiceDetail from 'pages/service-detail'
import Careers from 'pages/careers'
import Contact from 'pages/contact'

import NotFound from 'pages/notfound'

const pageRegistry = {
	Home: Home,
	About: About,
	Services: Services,
	ServiceDetail: ServiceDetail,
	Careers: Careers,
	Contact: Contact,
	NotFound: NotFound,
}

export default class Navigator extends React.Component {
	constructor(props) {
		super(props);

		changePage();
	}

	componentDidMount() {
		window.dynamicHistory = history;

		history.listen(function (e) {
			let route = getRouteFromUrl(e.pathname, false, true);
			changePage(route[0], route[1]);
		});
	}

	render() {
		//let routeData = renderRoutes();
		return (
			<div className="site-content">
				<DomWatcher />
				<Header />
				<div className="router-wrap">
					<Cover />
					{renderRoutes()}
				</div>
				<Footer />
			</div>
		)
	}
}

export function redirect(opts, params = false, getParams = false) {
	const defaultOpts = {
		type: 'push'
	}

	opts = (Object.prototype.toString.call(opts) === "[object String]" ? extend({}, defaultOpts, { to: opts }) : extend({}, defaultOpts, opts));

	let routeObj = getRoute(opts.to)
	let route = routeObj.path;

	console.log(routeObj);

	if (params) {
		for (let k = 0; k < Object.keys(params).length; k++) {
			let key = Object.keys(params)[k];
			route = route.replace(':' + key + '?', params[key]).replace(':' + key, params[key]);
		}
	}

	let getString = "";
	if (getParams) {
		for (let p = 0; p < Object.keys(getParams).length; p++) {
			let key = Object.keys(getParams)[p];

			if (getString !== "") {
				getString += "&";
			}
			getString += key + "=" + encodeURIComponent(getParams[key]);
		}
	}

	if (route) {
		route = route.split('/:')[0];
		if (getString !== "") { route = route + "?" + getString }
		switch (opts.type) {
			case "replace":
				history.replace(route);
				break;
			default:
				history.push(route);
				break;
		}
		changePage();
		return true;
	}
	else {
		return false;
	}
}

export function getRoute(key = false, group = 'pages') {
	let routeGroup = group;
	let keyParts = key.split('.');
	if (keyParts.length === 2) {
		routeGroup = keyParts[0];
		key = keyParts[1];
	}

	let target = routes[routeGroup][key];
	return (target ? target : false);
}

export function getRouteFromUrl(url = false, getObject = false, includeCatch = false) {
	if (url === false) { url = window.location.pathname.replace(/\/$/, ''); }
	let returnRoute = false;
	let catchRoute = false;
	Object.keys(routes).forEach((groupKey, index) => {
		let group = routes[groupKey];

		Object.keys(group).forEach((key, index) => {
			let route = routes[groupKey][key];
			if (route.path) {
				let match = matchPath(url, route.path);
				if (match && match.isExact) {
					if (getObject) {
						returnRoute = route;
						returnRoute.key = key;
						returnRoute.groupKey = groupKey;
					}
					else {
						returnRoute = [key, groupKey];
					}
				}
			}
			else if (includeCatch) {
				catchRoute = (getObject ? route : [key, groupKey]);
			}
		});
	});

	return (returnRoute ? returnRoute : catchRoute);
}

export function getCurrentRoute(url = false, includeCatch = true) {
	return getRouteFromUrl(false, true, true);
}

export function changeURLParam(value, param, route = false, noMismatch = false) {
	let routeObject = (route === false ? getCurrentRoute() : routes[route]);
	let data = false;

	if (routeObject) {
		data = routeObject.path.replace(':' + param + '?', value).replace(':' + param, value);
		if (noMismatch && data === routeObject.path) {
			data = false;
		}
	}

	return data;
}

export function changePage(key = false, group = 'pages') {
	let route = (key ? routes[group][key] : getRouteFromUrl(false, true, true));
	if (route.key) {
		key = route.key;
		group = route.groupKey;
	}

	let pageData = {
		key: key,
		group: group,
		fullKey: group + "." + key,
		data: route
	}
	if (store.getState().generic.currentPage.key !== key) {
		window.scroll(0, 0);
		store.dispatch(setPage(pageData));

		if (window.location.hash) {
			setTimeout(function () {
				let hashTarget = document.querySelector(window.location.hash)
				if (hashTarget) {
					hashTarget.scrollIntoView();
				}
			}, 500);
		}
	}

	setTitle(route.title);
	if (route.description) {
		setDescription(route.description);
	}

	setMeta((route.meta ? route.meta : false), true);
	setHead((route.head ? route.head : false), true);
	setHead([
		{
			key: "link",
			props: {
				rel: "canonical",
				href: window.location.href,
			}
		},
		{
			key: "meta",
			props: {
				property: "og:url",
				content: window.location.href,
			}
		}
	]);
}

export function renderRoutes(opts = {}) {
	const defaultOpts = {
		registry: pageRegistry,
		group: 'pages',
		catchRedirect: false,
	}
	opts = extend({}, defaultOpts, opts);
	let routeData = Object.keys(routes[opts.group]).map((key, index) => {
		let route = routes[opts.group][key]
		let routeProps = {
			key: index,
			exact: route.exact,
			component: opts.registry[route.component],
			name: route.component
		}

		if (route.path) {
			routeProps.path = route.path;
		}

		if (route.status) {
			routeProps.status = route.status;
		}

		return <Route {...routeProps} />
	});

	if (opts.catchRedirect) {
		let catchOpts = opts.catchRedirect.split('.');
		let to = routes[(catchOpts.length > 1 ? catchOpts[0] : 'pages')][catchOpts[catchOpts.length - 1]].path;
		routeData.push(<Redirect to={to} key="redir" />)
	}

	return <Switch>{routeData}</Switch>;
}